import React, {useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from '../components/seo';
import SibManagerAPI from '../services/sendinblue';
import { navigateUrl, URL } from "../utils/Utils";
import ConverterParameters from '../components/converter/models/ConverterParameters';
import '../pages/styles/landing.scss';

const landingPage = ({location}) => {
    const parameters = new ConverterParameters(location.search);

    const [htmlContent, setHtmlContent] = useState("");

    const fetchHtmlContent = async () => {
        try {
            const templateInfo = await SibManagerAPI.getLandingBody(parameters.getTemplateTag());
            if(templateInfo && templateInfo.status === 200) {
                setHtmlContent(templateInfo.data.html_content);
            }
            else {
                setHtmlContent("");
                navigateUrl(`/${URL.NOT_FOUND}`);
            }
        } catch (error) {
            navigateUrl(`/${URL.NOT_FOUND}`);
        }
    }

    useEffect(() => {
        fetchHtmlContent()
    })
    return (
        
        <Layout isShowAction={false} minHeight="100%">
            <Seo title="landing" />
            <div className="landing-page">
                <iframe 
                    srcDoc={htmlContent} 
                    title="dummy" 
                    ></iframe>
            </div>
        </Layout>

      ) 
};

export default landingPage;